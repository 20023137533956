<template>
  <div>
    <el-dialog
      :title="title"
      :before-close="handleClose"
      :visible.sync="dialog"
      width="90%"
      direction="rtl"
      custom-class="demo-drawer"
    >
      <div>
        <el-date-picker
          clearable
          size="small"
          style="width: 200px"
          v-model="years"
          type="year"
          value-format="yyyy"
          format="yyyy年"
          placeholder="请选择年份"
          :disabled="editShow"
        ></el-date-picker>

        <el-button @click="addAttributionButton">新增类目</el-button>
        <el-table :data="tableData" ref="addEditForm">
          <el-table-column label="序号" align="center" width="56" fixed>
            <template slot-scope="scope">
              <el-radio
                :label="scope.$index"
                v-model="radio"
                @change.native="getCurrentRow(scope.row)"
              ></el-radio>
            </template>
          </el-table-column>
          <el-table-column label="归属类目" align="center" width="140" fixed>
            <template slot-scope="scope">
              <template v-if="!scope.row.editShow">
                <el-form :model="scope.row">
                  <el-select
                  filterable
                    v-model="scope.row.attributionMone"
                    placeholder="请选择"
                    @change="
                      selectDisposeMonth(
                        scope.$index,
                        tableData,
                        scope.row.attributionMone
                      )
                    "
                  >
                    <el-option
                      v-for="item in attributionKeyList"
                      :key="item.key"
                      :label="item.label"
                      :value="item"
                    ></el-option>
                  </el-select>
                </el-form>
              </template>
              <span v-else>{{ scope.row.attributionCategoryName }}</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center" fixed>
            <template slot-scope="scope">
              <el-button
                @click.native.prevent="aveMoneyButton(scope.$index, tableData)"
                type="text"
                size="small"
                >一键平均</el-button
              >
              <el-button
                @click.native.prevent="copyMoneyButton(scope.$index, tableData)"
                type="text"
                size="small"
                >一键赋值</el-button
              >
              <el-button
                @click.native.prevent="
                  clearMoneyButton(scope.$index, tableData)
                "
                type="text"
                size="small"
                >一键清空</el-button
              >
              <el-button
                @click.native.prevent="deleteRow(scope.$index, tableData)"
                type="text"
                size="small"
                >移除</el-button
              >
            </template>
          </el-table-column>

          <el-table-column label="计划名称" align="center" fixed>
            <template slot-scope="scope">
              <el-form :model="scope.row">
                <el-form-item>
                  <el-input v-model="scope.row.planName">{{
                    scope.row.planName
                  }}</el-input>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>

          <el-table-column label="本金" align="center" fixed width="100">
            <template slot-scope="scope">
              <el-form :model="scope.row">
                <el-form-item>
                  <el-input v-model="scope.row.capital"
                    @blur="buildPreSumMoney(scope.row)"
                  >{{
                    scope.row.capital
                  }}</el-input>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>

          <el-table-column label="目标金额" align="center" fixed width="100">
            <template slot-scope="scope">
              <el-form :model="scope.row">
                <el-form-item>
                  <el-input v-model="scope.row.targetMoney">{{
                    scope.row.targetMoney
                  }}</el-input>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="每月金额" align="center" fixed width="100">
            <template slot-scope="scope">
              <el-form :model="scope.row">
                <el-form-item>
                  <el-input v-model="scope.row.monthMoeny"
                  >{{
                    scope.row.monthMoeny
                  }}</el-input>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="一月" align="center" width="100">
            <template slot-scope="scope">
              <el-form :model="scope.row">
                <el-form-item>
                  <el-input
                    v-model="scope.row.janMonth"
                    @blur="buildPreSumMoney(scope.row)"
                    >{{ scope.row.janMonth }}</el-input
                  >
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="二月" align="center" width="100">
            <template slot-scope="scope">
              <el-form :model="scope.row">
                <el-form-item>
                  <el-input
                    v-model="scope.row.febMonth"
                    @blur="buildPreSumMoney(scope.row)"
                    >{{ scope.row.febMonth }}</el-input
                  >
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="三月" align="center" width="100">
            <template slot-scope="scope">
              <el-form :model="scope.row">
                <el-form-item>
                  <el-input
                    v-model="scope.row.marMonth"
                    @blur="buildPreSumMoney(scope.row)"
                    >{{ scope.row.marMonth }}</el-input
                  >
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="四月" align="center" width="100">
            <template slot-scope="scope">
              <el-form :model="scope.row">
                <el-form-item>
                  <el-input
                    v-model="scope.row.aprMonth"
                    @blur="buildPreSumMoney(scope.row)"
                    >{{ scope.row.aprMonth }}</el-input
                  >
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="五月" align="center" width="100">
            <template slot-scope="scope">
              <el-form :model="scope.row">
                <el-form-item>
                  <el-input
                    v-model="scope.row.mayMonth"
                    @blur="buildPreSumMoney(scope.row)"
                    >{{ scope.row.mayMonth }}</el-input
                  >
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="六月" align="center" width="100">
            <template slot-scope="scope">
              <el-form :model="scope.row">
                <el-form-item>
                  <el-input
                    v-model="scope.row.junMonth"
                    @blur="buildPreSumMoney(scope.row)"
                    >{{ scope.row.junMonth }}</el-input
                  >
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="七月" align="center" width="100">
            <template slot-scope="scope">
              <el-form :model="scope.row">
                <el-form-item>
                  <el-input
                    v-model="scope.row.julMonth"
                    @blur="buildPreSumMoney(scope.row)"
                    >{{ scope.row.julMonth }}</el-input
                  >
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="八月" align="center" width="100">
            <template slot-scope="scope">
              <el-form :model="scope.row">
                <el-form-item>
                  <el-input
                    v-model="scope.row.augMonth"
                    @blur="buildPreSumMoney(scope.row)"
                    >{{ scope.row.augMonth }}</el-input
                  >
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="九月" align="center" width="100">
            <template slot-scope="scope">
              <el-form :model="scope.row">
                <el-form-item>
                  <el-input
                    v-model="scope.row.setpMonth"
                    @blur="buildPreSumMoney(scope.row)"
                    >{{ scope.row.setpMonth }}</el-input
                  >
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="十月" align="center" width="100">
            <template slot-scope="scope">
              <el-form :model="scope.row">
                <el-form-item>
                  <el-input
                    v-model="scope.row.octMonth"
                    @blur="buildPreSumMoney(scope.row)"
                    >{{ scope.row.octMonth }}</el-input
                  >
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="十一月" align="center" width="100">
            <template slot-scope="scope">
              <el-form :model="scope.row">
                <el-form-item>
                  <el-input
                    v-model="scope.row.nocMonth"
                    @blur="buildPreSumMoney(scope.row)"
                    >{{ scope.row.nocMonth }}</el-input
                  >
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="十二月" align="center" width="100">
            <template slot-scope="scope">
              <el-form :model="scope.row">
                <el-form-item>
                  <el-input
                    v-model="scope.row.decMonth"
                    @blur="buildPreSumMoney(scope.row)"
                    >{{ scope.row.decMonth }}</el-input
                  >
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="备注" align="center" width="100">
            <template slot-scope="scope">
              <el-form :model="scope.row">
                <el-form-item>
                  <el-input v-model="scope.row.remark">{{
                    scope.row.remark
                  }}</el-input>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>

          <el-table-column
            label="预计总金"
            align="center"
            fixed="right"
            width="100"
          >
            <template slot-scope="scope">
              <el-form :model="scope.row">
                <el-form-item>
                  <el-input v-model="scope.row.preSumMoney">{{
                    scope.row.preSumMoney
                  }}</el-input>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
        </el-table>
        <el-button @click="cancelForm">取 消</el-button>
        <el-button type="primary" @click="addFormButton()" :loading="loading">{{
          loading ? "提交中 ..." : "确 定"
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  listyearplanApi,
  addBatchyearplanApi,
  editBatchyearplanApi,
  selectBatchByIdyIdApi,
} from "@/api/work/plan/yearplan.js";
import { getEnumList,getWorkEnumList } from "@/api/commonApi/commonInternal/commonInternal";
export default {
  data() {
    return {
      years: null,
      dialog: false,
      loading: false,
      editShow: false,
      title: null,
      addEditForm: {},
      timer: null,
      ids: null,
      tableData: [],
      radio: null,
      attributionKeyList: [],
      dataFrom: {},
      year: null,
      caopType: null,
    };
  },
  methods: {
    show(id, type, year) {
      this.dialog = true;
      this.caopType = type;
      if (type == 2) {
        this.attributionKeyListButton();
        this.selectByIdButton(year);
        this.editShow = true;
        this.title = "编辑";
      }
      if (type == 1) {
        this.title = "新增";
      }
    },
    indexMethod(index) {
      return index + 1;
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then((_) => {
          this.cancelForm();
          this.dialog = false;
        })
        .catch((_) => {});
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      this.editShow = false;
      this.tableData = [];
      this.$parent.selectDataListButton();
    },
    addFormButton() {
      console.log(this.tableData);
      if (this.years == null || this.years == undefined)
        return this.$message.error("请选择年份");
      if (this.caopType == 1) {
        //新增
        addBatchyearplanApi({
          year: this.years,
          xunYearPlanReqVOS: this.tableData,
        }).then((res) => {
          this.cancelForm();
        });
      } else {
        editBatchyearplanApi({
          year: this.years,
          xunYearPlanReqVOS: this.tableData,
        }).then((res) => {
          this.cancelForm();
        });
      }
    },
    //查询详情
    selectByIdButton(year) {
      selectBatchByIdyIdApi({ year: year }).then((res) => {
        this.years = res.result.year;
        this.tableData = res.result.xunYearPlanReqVOS;
      });
    },

    addAttributionButton() {
      var dataFromInit = {
        planName: null,
        years: null,
        capital: 0,
        preSumMoney: 0,
        janMonth: 0,
        febMonth: 0,
        marMonth: 0,
        aprMonth: 0,
        mayMonth: 0,
        junMonth: 0,
        editShow: false,
        julMonth: 0,
        augMonth: 0,
        setpMonth: 0,
        octMonth: 0,
        nocMonth: 0,
        decMonth: 0,
        targetMoney: 0,
        monthMoeny: 0,
        attributionKey: "1",
        attributionCategory: "2",
        status: 1,
        remark: null,
      };
      this.tableData.push(dataFromInit);
      this.attributionKeyListButton();
    },
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      this.ids = row.id;
      this.optFrom = row;
    },
    attributionKeyListButton() {
      getWorkEnumList({ enumName: "AssignEnum" }).then((resp) => {
        this.attributionKeyList = resp.result;
      });
    },
    aveMoneyButton(index, rows) {
      var aveMoney =
        (parseInt(rows[index].targetMoney) - parseInt(rows[index].capital)) /
        12;
      var sumMoney =
        parseInt(rows[index].targetMoney) + parseInt(rows[index].capital);
      this.buildMoney(index, aveMoney, rows, sumMoney);
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    clearMoneyButton(index, rows) {
      var aveMoney = 0;
      this.buildMoney(index, aveMoney, rows);
    },
    copyMoneyButton(index, rows) {
      var sumMoney =
        parseInt(rows[index].monthMoeny) * 12 + parseInt(rows[index].capital);
      this.buildMoney(index, parseInt(rows[index].monthMoeny), rows, sumMoney);
    },
    buildMoney(index, money, rows, sumMoney) {
      rows[index].janMonth = money;
      rows[index].febMonth = money;
      rows[index].marMonth = money;
      rows[index].aprMonth = money;
      rows[index].mayMonth = money;
      rows[index].junMonth = money;
      rows[index].julMonth = money;
      rows[index].augMonth = money;
      rows[index].setpMonth = money;
      rows[index].octMonth = money;
      rows[index].nocMonth = money;
      rows[index].decMonth = money;
      rows[index].monthMoeny = money;
      rows[index].preSumMoney = sumMoney;
    },
    selectDisposeMonth(index, rows, attributionMone) {
      console.log(attributionMone);
      rows[index].attributionKey = attributionMone.key;
      rows[index].attributionCategory = attributionMone.value;
    },
    buildPreSumMoney(row) {
      console.log("==", row);
      row.preSumMoney =
      Number(row.capital)+
        Number(row.janMonth) +
        Number(row.febMonth) +
        Number(row.marMonth) +
        Number(row.aprMonth) +
        Number(row.mayMonth) +
        Number(row.junMonth) +
        Number(row.julMonth) +
        Number(row.augMonth) +
        Number(row.setpMonth) +
        Number(row.octMonth) +
        Number(row.nocMonth) +
        Number(row.decMonth);
    },
  },
};
</script>
<style scoped>
</style>



