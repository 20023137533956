import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/work/xunYearPlan'


const listyearplanApi =(params)=>getAction(baseURL+"/list",params);

const groupByYearApi =(params)=>getAction(baseURL+"/groupByYear",params);

const addBatchyearplanApi=(params)=>postAction(baseURL+"/addBatch",params);

const addyearplanApi=(params)=>postAction(baseURL+"/add",params);

const edityearplanApi=(params)=>putAction(baseURL+"/edit",params);

const editBatchyearplanApi=(params)=>putAction(baseURL+"/editBatch",params);

const deleteyearplanByIdApi=(params)=>deleteByIdAction(baseURL+'/deleteById',params);


const selectBatchByIdyIdApi=(params)=>getAction(baseURL+'/selectBatchById',params);

const selectyearplanByIdApi=(params)=>getByIdAction(baseURL+'/selectById',params);

export{
    selectyearplanByIdApi,
    deleteyearplanByIdApi,
    edityearplanApi,
    addyearplanApi,
    listyearplanApi,
    addBatchyearplanApi,
    editBatchyearplanApi,
    groupByYearApi,
    selectBatchByIdyIdApi
}
